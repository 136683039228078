import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles.css";
import "./styles.css";
import Header from "../../components/Header";
import Select from "react-select";
import GeoSearch from "../../components/GeoSearch";
import DownArrowWave from "../../components/DownArrowWave";
import DailyLunchView from "../../components/LunchCards/DailyLunchView";
import RestaurantShowMore from "../../components/RestaurantShowMore";
import { searchOptions } from "../../data";
import { sortOptions } from "../../data";
import LunchDaySelector from "../../components/LunchDaySelector";
import moment from "moment";

function StartPage() {
    const [menus, setMenus] = useState([]);
    const [filterTerms, setFilterTerms] = useState([]);
    const [geoPosition, setGeoPosition] = useState([16.397202, 61.3513]); // Default position is Bollnäs Kommun
    const [hasAnsweredLocation, setHasAnsweredLocation] = useState(false);
    const [sortTerm, setSortTerm] = useState("distance_asc");
    const [fetchedData, setFetchedData] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [restaurantInfo, setRestaurantInfo] = useState({}); // Modal state
    const [chosenWeek, setChosenWeek] = useState(moment().week());
    const [chosenDayOfWeek, setChosenDayOfWeek] = useState(moment().isoWeekday() - 1);

    useEffect(() => {
        navigator.permissions
            .query({ name: "geolocation" })
            .then(function(result) {
                if (result?.state === 'granted') {
                    navigator.geolocation.getCurrentPosition((geoLocationObject) => {
                        const position = geoLocationObject.coords;
                        setGeoPosition([position.longitude, position.latitude]);
                    });
                    setHasAnsweredLocation(true);
                } else {
                        setHasAnsweredLocation(true);
                }
            });
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`/api/lunchmenu/weekly/${chosenWeek}`);
                setMenus(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setFetchedData(true);
        }
        fetchData();
    }, [chosenWeek]);

    const resetFilter = () => {
        setFilterTerms([]);
    };

    // Function to toggle modal
    const openModal = (restaurantInfo) => {
        if (isModalOpen) return;
        setRestaurantInfo(restaurantInfo);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371000; // Radius of the earth in m
        var dLat = deg2rad(lat2 - lat1);
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in m
        return parseInt(d);
    }

    function transformDistanceToString(distance) {
        if (distance.toFixed(2) < 1000) {
            return distance.toFixed(0) + " m";
        }
        return (distance / 1000).toFixed(2) + " km";
    }

    const setLunchDay = (option) => {
        setChosenWeek(option.week);
        setChosenDayOfWeek(option.dayIndex);
    };

    const setSelectedSearch = (inputValue) => {
        setGeoPosition([inputValue.longitude, inputValue.latitude]);
    };

    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    function compareFilter(meals) {
        const filterValues = filterTerms.map((x) => x.value);
        if (Array.isArray(meals) && meals.length > 0) {
            return meals.filter((meal) => {
                const mealTags = meal?.tags?.map((tag) => tag.value) ?? [];
                if (filterValues.length > 0) {
                    for (const mealTag of mealTags) {
                        if (filterValues.includes(mealTag)) return true;
                    }
                    return false;
                }
                return true;
            });
        }

        return meals;
    }

    const filteredMenus = menus
        .map((menu) => {
            const dayOfWeek = chosenDayOfWeek;
            menu.meals = compareFilter(menu.lunches[dayOfWeek]) ?? [];
            return {
                ...menu,
                name: menu.restaurant.name,
                price: menu.restaurant.default_price,
                distance: getDistanceFromLatLonInKm(
                    geoPosition[1],
                    geoPosition[0],
                    menu.restaurant?.position?.latitude ?? 0,
                    menu.restaurant?.position?.longitude ?? 0,
                ),
            };
        })
        .filter((menu) => {
            // Filter according to food filter tags
            if (
                Array.isArray(filterTerms) &&
                filterTerms.length > 0 &&
                menu.meals.length === 0
            ) {
                return false;
            }

            /*
                const max_distance = 70_000 // max radius 70km
            if (menu.distance > max_distance) {
                return false;
            }
            */
            return true;
        })
        .sort((a, b) => {
            const sortOn = sortTerm.split("_")[0];
            const sortDirection = sortTerm.split("_")[1];
            if (a[sortOn] < b[sortOn]) {
                return sortDirection === "asc" ? -1 : 1;
            }
            if (a[sortOn] > b[sortOn]) {
                return sortDirection === "asc" ? 1 : -1;
            }
            return 0;
        })
        .map((menu, index) => {
            return {
                ...menu,
                distance: transformDistanceToString(menu.distance),
                index: index,
            };
        });

    return (
        <div className="bg-black">
            <div className="start-page-first-section">
                <div>
                    <header className="custom-header position-relative py-4 text-center">
                        <h1 className="display-1">
                            <Header title="Matsedeln" />
                        </h1>
                        <h3 className="text-center">Vad blir det för lunch idag?</h3>
                    </header>
                </div>
                <button
                    id="down-arrow-button"
                    className="btn position-absolute start-50 translate-middle-x w-100 bottom-0 mb-4 rounded"
                    onClick={() => {
                        const contentPageTop = document.getElementById(
                            "lunch-list-container",
                        ).offsetTop;
                        window.scrollTo({
                            top: contentPageTop,
                            behavior: "smooth",
                        });
                    }}
                >
                    <DownArrowWave />
                </button>
            </div>
            <div className="lunch-list-container" id="lunch-list-container">
                <div
                    className="menu-container col-12 col-md-10 container mt-5"
                    id="startpage-search-container"
                >
                    <div className="row mb-md-3">
                        <div className="col-12 col-md-6 mt-2">
                            <GeoSearch onChange={setSelectedSearch} />
                        </div>
                        <div className="col-12 col-md-6 mt-2">
                            {/* <button className='btn btn-primary active w-100'>Dagens Luncher</button> */}
                            <LunchDaySelector onChange={setLunchDay} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 mt-2">
                            <Select
                                isMulti
                                name="Filters"
                                options={searchOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Matpreferenser..."
                                value={filterTerms}
                                isSearchable={false}
                                onChange={(e) => setFilterTerms(e)}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-2">
                            <Select
                                name="Sortera"
                                options={sortOptions}
                                className="basic-select"
                                classNamePrefix="select"
                                placeholder="Sortera..."
                                isSearchable={false}
                                onChange={(e) => setSortTerm(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="menu-container col-12 col-md-10 container mt-5">
                    {filteredMenus.length > 0 && hasAnsweredLocation &&
                        filteredMenus.map((menu) => (
                            <div key={menu.id} className="lunch-card-container">
                                <DailyLunchView
                                    menu={menu}
                                    index={menu.index}
                                    onClick={() => openModal(menu.restaurant)}
                                />
                            </div>
                        ))}
                    {filteredMenus.length === 0 && fetchedData === true && (
                        <div className="container">
                            <button className="btn btn-primary" onClick={() => resetFilter()}>
                                Återställ Filter
                            </button>
                        </div>
                    )}
                </div>
                <RestaurantShowMore
                    show={isModalOpen}
                    restaurantInfo={restaurantInfo}
                    closeModal={closeModal}
                />
            </div>
        </div>
    );
}

export default StartPage;
