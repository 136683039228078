// PasswordChangeForm.js
import React, { useState } from 'react';

const PasswordChangeForm = (userDetails) => {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleSubmitPasswordChange = (e) => {
    e.preventDefault();
    if (passwords.newPassword !== passwords.repeatNewPassword) {
      alert('Du har skrivit två olika nya lösenord!');
      return;
    }
    fetch('/api/settings/password', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(passwords)
    }).then(response => {
      if (response.status === 200) {
        alert('Lösenordet har ändrats!');
      } else {
        alert('Kunde inte ändra lösenordet. Försök igen!');
        console.debug('Error changing password:', response);
      }
    }).catch(error => {
        alert('Kunde inte ändra lösenordet. Försök igen!')
        console.debug('Error changing password:', error);
      });
    // Add logic to change password
  };

  return (
    <div className="row p-3 mb-4 bg-gray-500 rounded-2">
      <div className="col">
        <h4 className='mb-3'>Ändra Lösenord</h4>
        <form onSubmit={handleSubmitPasswordChange}>
        <input type="text" className="form-control" name="username" value={userDetails.username} autoComplete="username" style={{ display: 'none' }}/>
          <div className="form-group mb-3">
            <label>Nuvarande Lösenord</label>
            <input type="password" className="form-control" name="currentPassword" autoComplete='current-password' onChange={handlePasswordChange} />
          </div>
          <div className="form-group mb-3">
            <label>Nytt Lösenord</label>
            <input type="password" className="form-control" name="newPassword" autoComplete='new-password' onChange={handlePasswordChange} />
          </div>
          <div className="form-group mb-3">
            <label>Repetera Nytt Lösenord</label>
            <input type="password" className="form-control" name="repeatNewPassword" autoComplete='new-password' onChange={handlePasswordChange} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Ändra Lösenord</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordChangeForm;
