import React from "react";
import {
  faLocationDot,
  faCar,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

const DailyLunchView = ({ menu, index, onClick }) => {
  return (
    <div
      key={menu.id}
      data-index={index}
      className={`menu-item cards-container mb-4 start-animation`}
      style={{ animationDelay: `${index * 0.3}s` }}
    >
      <div className="cards w-100 lunchcard-daily" onClick={onClick}>
        <div className="row card-row m-0">
          <div className="col-md-3 lunch-card-img bg-lunchdark d-flex justify-content-center flex-column">
            <div className="lunch-card-image-div p-2">
              <img
                src={`images/${menu.restaurant.logo}`}
                alt="Logo"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col card-description-body">
            <div className="card-restaurant-name-header p-1 bg-lunchdark text-white text-uppercase fs-5 font-weight-bold">{menu.restaurant.name}</div>
            <div className="cards-body">
              {menu.meals.map((lunch, index) => (
                <div key={index} className="cards-text">
                  <div className="row">
                    <div className="col-8">
                      <p className="cards-text">{lunch.description}</p>
                    </div>
                    <div className="col-4">
                      <p className="cards-text text-end">{lunch.price} kr</p>
                    </div>
                  </div>
                </div>
              ))}
              <br />
              <div className="cards-text mb-0">
                <div className="row">
                  <div className="col">
                    <p className="cards-text mb-0">
                      <small className="text-muted">
                        {menu.restaurant.standing_message}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row cards-footer m-0 bg-lunchdark text-white">
          <div className="row">
            <div className="col col-4">
              <div className="row">
                <div className="col">
                  <div className="card-footer-text overflow-x-auto text-nowrap text-center">
                    <FontAwesomeIcon icon={faLocationDot} className="me-1" />
                    {`${menu.restaurant.address || ""} ${menu.restaurant.house_number} - ${menu.restaurant.county}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-4">
              <div className="row">
                <div className="col">
                  <div className="card-footer-text overflow-x-auto text-nowrap text-center">
                    <FontAwesomeIcon icon={faCar} className="me-1" />{" "}
                    {menu.distance}
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-4">
              <div className="row">
                <div className="col">
                  <div className="card-footer-text overflow-x-auto text-nowrap text-center">
                    <FontAwesomeIcon icon={faPhone} className="me-1" />{" "}
                    <a
                      className="text-decoration-none text-white"
                      href={"tel:" + String(menu.restaurant.tele)}
                    >
                      {menu.restaurant.tele}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyLunchView;
