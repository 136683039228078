import { useEffect } from 'react';
/* global dataLayer */

const GoogleAnalytics = ({ isEnabled }) => {
  useEffect(() => {
    if (isEnabled) {
      // Load Google Analytics script
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-K5NL0ZJ0CD';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-K5NL0ZJ0CD');
      };

      return () => {
        // Clean up script when component unmounts
        document.body.removeChild(script);
      };
    }
  }, [isEnabled]);

  return null; // Google Analytics doesn't render anything to the DOM
};

export default GoogleAnalytics;
