// src/components/Auth/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../context/UserContext';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { updateUserContext } = useContext(UserContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', {
        name:username,
        password:password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      await updateUserContext(response.data.user, true);
      navigate("/");
    } catch (error) {
        alert('Kunde inte logga in med de angivna uppgifterna. Försök igen!')
        console.error("Error logging in:", error);
    }
  };

  return (
    <div className="container min-vh-100 h-100 position-relative">
      <div className="row justify-content-center w-100 position-absolute top-50 start-50 translate-middle">
        <div className="col-md-6">
          <h2 className="mb-4">Logga in</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input 
                type="text" 
                value={username} 
                onChange={e => setUsername(e.target.value)} 
                autoComplete='username'
              placeholder="Användarnamn"
                required 
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input 
                type="password" 
                value={password} 
                onChange={e => setPassword(e.target.value)} 
                autoComplete='current-password'
                autoCorrect='on'
                placeholder="Lösenord"
                required 
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-primary ms-0">
              <FontAwesomeIcon icon={faUser} /> Logga in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
