import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function DownArrowWave() {
    return (
        <div className='wave-icon-container'>
            <FontAwesomeIcon icon={faChevronDown} className='wave-icon' />
            <FontAwesomeIcon icon={faChevronDown} className='wave-icon' style={{ top: '20%' }} />
            <FontAwesomeIcon icon={faChevronDown} className='wave-icon' style={{ top: '40%' }} />
        </div>
    );
}

export default DownArrowWave;
