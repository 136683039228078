// src/components/Auth/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        alert("Lösenorden matchar inte!");
        return;
      }
      try {
        await axios.post('/api/auth/register', {
          name:username,
          password:password,
          companyRegistrationNumber:companyRegistrationNumber,
        });
        navigate("/login");
      } catch (error) {
        alert("Kunde inte registrera användaren: " + error.response.data.message)
        console.error("Error under registrering:", error);
      }
    };
  
    return (
      <div className="container min-vh-100 h-100 position-relative">
        <div className="row justify-content-center w-100 position-absolute top-50 start-50 translate-middle">
          <div className="col-md-6">
            <h2 className="mb-4">Registrera nytt konto</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input 
                  type="text" 
                  value={username} 
                  onChange={e => setUsername(e.target.value)} 
                  placeholder="Användarnamn"
                  required 
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input 
                  type="password" 
                  value={password} 
                  onChange={e => setPassword(e.target.value)} 
                  placeholder="Lösenord"
                  autoComplete='new-password'
                  required 
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <input 
                  type="password" 
                  value={confirmPassword} 
                  onChange={e => setConfirmPassword(e.target.value)} 
                  placeholder="Bekräfta lösenord"
                  autoComplete='new-password'
                  required 
                  className="form-control"
                />
              </div>
              <hr />
              <div className="mb-3">
                <input 
                  type="text" 
                  value={companyRegistrationNumber}
                  onChange={e => setCompanyRegistrationNumber(e.target.value)}
                  placeholder="Registreringskod"
                  required 
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faUserPlus} /> Registrera dig
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
  
  export default Register;
