import React from 'react';
import './styles.css';

function Matsedeln404Component() {
    return (
        <div className='container'>
            <div className='position-absolute top-50 start-50 translate-middle text-center'>
                <h1>
                    404
                </h1>
                <h3>
                    Sidan du försökte navigera till existerar inte!
                </h3>
            </div>
        </div>
    );
}

export default Matsedeln404Component;
