import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // Function to update user info
  const updateUserContext = (newUserInfo = null, loggedIn = false) => {
    setUserInfo(newUserInfo);
    setIsAdmin(newUserInfo?.admin);
    setLoggedIn(loggedIn);
  };

  const getUserInfo = () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoggedIn(false);
        setIsAdmin(false);
        setLoading(false);
        return
      };

      fetch('/api/auth/getuserinfo', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
        .then(response => {
          if (response >= 400) {
            throw Error('Authentication failed');
          } else {
            return response.json()
          }
        })       
        .then(data => {
          if (data.name) {
              setLoggedIn(true);
              setUserInfo(data);
              setIsAdmin(data?.admin || false);
          }
          setLoading(false);
        })
        .catch(error => {
          localStorage.removeItem('token');
          setLoggedIn(false);
          setIsAdmin(false);
          setLoading(false);
        });
      }
      catch (error) {
        setLoggedIn(false);
        setIsAdmin(false);
        setLoading(false);
      }
}

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, loggedIn, loading, isAdmin, updateUserContext, getUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};
