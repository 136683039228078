import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import './header.css'; 

const Header = ({ title }) => {
  return (
    <div className="header">
      <FontAwesomeIcon icon={faUtensils} className="fa-fork" />
      {title}
      <FontAwesomeIcon icon={faUtensils} className="fa-knife" />
    </div>
  );
};

export default Header;