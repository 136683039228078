import React, { useState } from 'react';
import Select from 'react-select';
import moment from 'moment';

const generateOptions = () => {
    const options = [];
    const dayNamesSwedish = {
        'Monday': 'Måndag',
        'Tuesday': 'Tisdag',
        'Wednesday': 'Onsdag',
        'Thursday': 'Torsdag',
        'Friday': 'Fredag',
        'Saturday': 'Lördag',
        'Sunday': 'Söndag'
    };

    // Get the current date and start of the week
    const today = moment();

    // Add option for today
    const todayIndex = today.isoWeekday() - 1; // Get the index of today (1 for Monday, 2 for Tuesday, ..., 7 for Sunday)
    options.push({
        value: todayIndex,
        label: `Idag - ${today.format('DD MMM')}`, // Convert day name to Swedish
        date: today.format('YYYY-MM-DD'),
        dayIndex: todayIndex,
        week: today.isoWeek(),
        today: true,
    });

    // Add options for the upcoming 7 days
    for (let i = 1; i <= 7; i++) {
        const nextDay = today.clone().add(i, 'days');
        const dayIndex = nextDay.isoWeekday() - 1; // Get the index of the next day
        let dayName = dayNamesSwedish[nextDay.format('dddd')];
        if (i === 1) dayName = 'Imorgon'
        options.push({
            value: `${nextDay.isoWeek()}${dayIndex}`,
            label: `${dayName} - ${nextDay.format('DD MMM')}`, // Convert day name to Swedish
            date: nextDay.format('YYYY-MM-DD'),
            dayIndex: dayIndex,
            week: nextDay.isoWeek(),
        });
    }

    return options;
};

const LunchDaySelector = ({ onChange }) => {
    const [selectedOption, setSelectedOption] = useState({});

    // Generate options using the function
    const options = generateOptions();

    // Set the default selected option to today
    useState(() => {
        const defaultOption = options.find(option => option.today === true);
        setSelectedOption(defaultOption);
    });

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption);
    };

    return (
        <div className="row">
            <div className="col">
                <div className="d-flex align-items-center">
                    <span className="font-weight-bold bg-primary text-white text-nowrap" style={{ padding: '6.9px', borderRadius: '0.25rem 0 0 0.25rem' }}>Visa luncher för:</span>
                    <div className="p-0 w-100 customLunchSelect">
                        <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={options}
                            isSearchable={false}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: '0 0.25rem 0.25rem 0'
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LunchDaySelector;
