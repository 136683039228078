// UserDetailsForm.js
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import GeoSearch from "../../components/GeoSearch";

const UserDetailsForm = ({ userInfo }) => {
  const { getUserInfo } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState({
    name: "",
    address: "",
    email: "",
    phoneNumber: "",
    companyNumber: "",
    company_website: "",
    houseNumber: "",
    county: "",
    position: {
      longitude: 0,
      latitude: 0,
    },
    standingMessage: "",
    welcome_message: "",
    defaultPrice: "",
  });

  const limit = !isNaN(parseInt(localStorage.getItem("geo_limit"))) ? parseInt(localStorage.getItem("geo_limit")) : 300;

  useEffect(() => {
    if (userInfo && userInfo.name) setUserDetails({ ...userInfo });
  }, [userInfo]);

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleGeoPositionChange = (e) => {
    const address = e.name;
    const county = e.county;
    const position = {
      longitude: e.longitude,
      latitude: e.latitude,
    };
    setUserDetails({
      ...userDetails,
      address,
      position,
      county,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      fetch("/api/settings/restaurant", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      })
        .then(() => {
          getUserInfo();
          alert("Ändringarna har sparats!");
        })
        .catch((error) => {
          alert("Kunde inte spara ändringarna. Försök igen!");
          console.debug("Error saving changes:", error);
        });
    } catch (error) {
      alert("Kunde inte spara ändringarna. Försök igen!");
      console.debug("Error saving changes:", error);
    }
    // Add logic to submit user details
  };

  return (
    <div className="row rounded-2 mb-4 bg-gray-500 p-3">
      <div className="col">
        <h4 className="mb-3">Ändra restaurangens uppgifter</h4>
        <h5 className="mb-3">
          <b>(Publikt)</b>
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label>Restaurangnamn</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={userDetails.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Adress</label>
            <GeoSearch
              className="text-dark"
              value={`${userDetails.address} - ${userDetails.county}`}
              limit={limit}
              position={userDetails.position}
              onChange={handleGeoPositionChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Gatunummer</label>
            <input
              type="text"
              className="form-control"
              name="houseNumber"
              value={userDetails.houseNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Telefonnummer</label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              value={userDetails.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Stående meddelande</label>
            <textarea
              className="form-control"
              name="standingMessage"
              value={userDetails.standingMessage}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="form-group mb-3">
            <label>Företagsmeddelande</label>
            <textarea
              className="form-control"
              name="welcome_message"
              value={userDetails.welcome_message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="form-group mb-3">
            <label>Hemsida</label>
            <input
              type="text"
              className="form-control"
              name="company_website"
              value={userDetails.company_website}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Standardpris</label>
            <input
              type="number"
              className="form-control"
              name="defaultPrice"
              value={userDetails.defaultPrice}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Spara Ändringar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserDetailsForm;
