// DeleteSection.js
import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const DeleteSection = () => {
    const [showModal, setShowModal] = useState(false);
    const [restaurantName, setRestaurantName] = useState('');
    const { userInfo } = useContext(UserContext);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleConfirmDelete = () => {
        const restaurant_name = userInfo?.employer?.name;

        if (restaurantName !== restaurant_name ) {
            alert('Restaurangens namn matchar inte med vad du skrivit. Försök igen!');
            return;
        }

        try {
            alert('Deleting restaurant: ' + restaurantName)
            fetch(`/api/settings/restaurant/`, {
                method: 'DELETE',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({ restaurantName }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message) {
                        alert(data.message);
                    }
                })
                .catch((error) => {
                    alert('Error removing restaurant:' + JSON.stringify(error));
                });
        } catch (error) {
            alert('Error removing restaurant: No restaurant ID found: ' + error.message || '');
        }

        setShowModal(false);
        setRestaurantName('');
    };

    return (
        <div className="row p-3 bg-gray-500 rounded-2">
            <div className="col">
                <h4 className='mb-3'>Permanenta Åtgärder</h4>
                <button className="btn btn-danger" onClick={openModal}>Ta bort restaurangen</button>
            </div>

            {showModal && (
                <div className="modal show" style={{ display: 'block', color:'black' }} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Bekräfta borttagning</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                Skriv in restaurangens namn för att bekräfta "<b>{userInfo?.employer?.name}</b>""
                                <input type="text" className="form-control" value={restaurantName} onChange={(e) => setRestaurantName(e.target.value)} />
                                
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Ta bort</button>
                            <button type="button" className="btn btn-secondary" onClick={closeModal}>Avbryt</button>
                            </div>
                        </div>
                    </div>
                </div>

                
            )}
        </div>
    );
}

export default DeleteSection;
