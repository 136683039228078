// RestaurantCodes.js
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';

const RestaurantCodes = () => {
    const [restaurantCodes, setRestaurantCodes] = useState([]);
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        if (userInfo?.employer?.id) {
            getRestaurantCodes();
        }
    }, [userInfo?.employer?.id]);

    const getRestaurantCodes = () => {
        fetch('/api/settings/restaurantcode', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setRestaurantCodes(data);
            })
            .catch((error) => {
                alert('Error getting restaurant codes:' + JSON.stringify(error));
            });
    };
    

  const generateRestaurantCode = () => {
    const restaurant_id = userInfo?.employer?.id
    if (restaurant_id) {
        fetch(`/api/settings/restaurantcode/${restaurant_id}`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code) {
                setRestaurantCodes([...restaurantCodes, data.code]);
            }
          })
          .catch((error) => {
            alert('Error generating restaurant code:' + JSON.stringify(error));
          });
    } else {
        alert('Error generating restaurant code: No restaurant ID found');
    }
  };

  return (
    <div className="row p-3 mb-4 bg-gray-500 rounded-2">
      <div className="col">
        <h4 className='mb-3'>Registreringskoder</h4>
        <ul>
            {restaurantCodes.map((code) => (
                <li key={code.id}>{code.registrationNumber} </li>
            ))}
        </ul>
        <button className="btn btn-primary" onClick={generateRestaurantCode}>Generera ny kod</button>
      </div>
    </div>
  );
};

export default RestaurantCodes;
