import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from './pages/Startpage';
import Login from './pages/Login';
import Register from './pages/Register';
import AddLunches from './pages/AddLunches';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings';
import Policy from './pages/Policy';
import TopMenu from './components/Menu';
import Footer from './components/Footer/';
import Matsedeln404Component from './components/Matsedeln404Component';
import { UserProvider } from './context/UserContext';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import GoogleAnalytics from './components/GoogleAnalytics';
import './styles/Ubuntu.css';
import './styles.css';
import './styles/custom.scss';

const cookies = new Cookies();

function App() {
  const [cookieConsent, setCookieConsent] = useState(cookies.get('rcl_consent_given') === true);
  const setCookieConsentTrue = () => {
    if (!cookieConsent) {
      setCookieConsent(true);
    }
  }

  let expires = new Date();
  expires.setMonth(expires.getMonth());

  return (
    <UserProvider>
      <GoogleAnalytics isEnabled={cookieConsent}/>
      <Router>
        <TopMenu/>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/addlunches" element={<AddLunches />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/policy" element={<Policy />} />
          <Route path='*' exact={true} status={404} element={<Matsedeln404Component/>} />
        </Routes>
        <Footer/>
      </Router>
    <CookieBanner
      message="Denna webbsida använder kakor för att förstå hur användare interagerar med sidan. Vi strävar efter att hela tiden kunna förbättra användarvänligheten. Ingen personlig data inhämtas utan direkt samtycke."
      className="bg-light bottom-0 top-auto"
      acceptAllButtonText="Acceptera alla"
      acceptSelectionButtonText="Acceptera val"
      policyLink="/policy"
      statisticsOptionInitiallyChecked={true}
      showMarketingOption={true}
      showPreferencesOption={false}
      showAcceptSelectionButton={true}
      showDeclinceButton={true}
      marketingOptionText="Marknadsföring"
      necessaryOptionText="Nödvändiga"
      statisticsOptionText="Statistik & Analys"
      privacyPolicyLinkText="Mer information om integritetspolicy"
      onAccept = {() => { 
          cookies.set('rcl_consent_given', true, { path: '/', expires: expires });
          setTimeout(() => setCookieConsentTrue(), 0);
      }}
    />
    </UserProvider>
  );
}

export default App;
