import React from 'react';

const Graph = ({ title }) => (
  <div className="card">
    <div className="card-body">
      <h3>{title}</h3>
      {/* Add graph rendering here */}
      <p>Graph Placeholder</p>
    </div>
  </div>
);

export default Graph;

