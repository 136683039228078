import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const generatePDF = async (week, restaurantId) => {
    try {
        const response = await axios.get(`/api/pdf/generate-weekly-lunch-pdf?week=${week}&restaurant=${restaurantId}`, {
            responseType: 'blob' // Set response type to blob
        });

        return response.data; // Return blob data
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const PDFModal = ({ isOpen, onClose, pdfBlob, week }) => {
    if (!isOpen) return null;

    const pdfUrl = URL.createObjectURL(pdfBlob);

    return (
        <div className="modal show" style={{ display: "block" }} tabIndex="-1">
            <div className='modal-dialog modal-lg h-100'>
                <div className="modal-content p-3 h-100">
                    <h5 className='text-center'>Lunchmeny för vecka {week}</h5>
                    <button type="button" className="btn-close position-absolute top-0 end-0 text-danger" onClick={onClose}></button>
                    <embed src={pdfUrl} type="application/pdf" width="100%" height="100%" />
                </div>
            </div>
        </div>
    );
}

const PDFViewerWeeklyLunch = ({ week, restaurantId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);

    const handleGeneratePDF = async (week, restaurantId) => {
        try {
            const blob = await generatePDF(week, restaurantId);
            setPdfBlob(blob);
            setIsModalOpen(true);
        } catch (error) {
            console.error(error);
        }
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setPdfBlob(null);
    }

    return (
        <div>
            <button className="w-100 h-100 btn btn-sm btn-primary mx-2 text-nowrap" onClick={() => handleGeneratePDF(week, restaurantId)}>
                <FontAwesomeIcon icon={faPrint} className='me-1'/>
                Skriv ut matsedeln
            </button>
            <PDFModal isOpen={isModalOpen} onClose={closeModal} pdfBlob={pdfBlob} week={week} />
        </div>
    );
}

export default PDFViewerWeeklyLunch;
