import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer className="footer bg-lunchdark text-white">
      <div className="container-fluid d-none d-md-block m-auto p-3">
        <div className="row">
          <div className="col">
            <h5>Kontakta Oss</h5>
            <ul className="list-unstyled">
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <a href="mailto:info@matsedeln.se" className="text-white">
                  info@matsedeln.se
                </a>
              </li>
            </ul>
          </div>
          <div className="col rl-divider">
            <h5>Policy</h5>
            <FontAwesomeIcon icon={faLink} className="me-1" />
            <a href="/policy" className="link-unstyled">
              Integritetspolicy
            </a>
          </div>
          <div className="col">
            <h5>Om Matsedeln</h5>
            <p>
              Letar du efter lunch? Då har du kommit rätt! Matsedeln är den
              ultimata platsen för att hitta och utforska lunchalternativ nära
              dig. Med användarvänliga filter kan du enkelt hitta precis det du
              är sugen på.
            </p>
          </div>
        </div>
      </div>
      <div className="row container-fluid d-md-none m-auto py-2">
        {" "}
        {/* Show only for small screens */}
        <div className="col text-center">
          <h5>Policy</h5>
          <FontAwesomeIcon icon={faLink} className="me-1" />
          <a href="/policy" className="link-unstyled">
            Integritetspolicy
          </a>
        </div>
        <div className="col text-center">
          <h5>Kontakta oss</h5>
          <FontAwesomeIcon icon={faEnvelope} className="me-1" />
          <a href="mailto:info@matsedeln.se" className="text-white">
            info@matsedeln.se
          </a>
        </div>
      </div>
      <div className="bg-dark py-3 text-center">
        © 2024 Matsedeln. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
