import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from "react-router-dom";
import Select from 'react-select'; // Import React Select
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const TopMenu = () => {
    const location = useLocation();
    const [restaurants, setRestaurants] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const { userInfo, loggedIn, updateUserContext, isAdmin, getUserInfo } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const [newRestaurantName, setNewRestaurantName] = useState('');

    const navigate = useNavigate();
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        updateUserContext();
        navigate("/");
    };

    const isActive = (path) => location.pathname === path;

    const setRestaurant = (restaurant_id) => {
        fetch(`/api/settings/changerestaurant/${restaurant_id}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        })
        .then(() => {
            setSelectedRestaurant(String(restaurant_id));
            getUserInfo();
        })
        .catch((error) => {
            alert('Error setting restaurant:' + JSON.stringify(error));
        })
    };

    
    useEffect(() => {
        if (loggedIn && isAdmin) {
            // Fetch restaurants
            fetch('/api/settings/restaurants', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then((response) => response.json())
            .then((data) => {
                const restaurantOptions = data.map(r => ({ value: r.id, label: r.name }));
                setRestaurants(restaurantOptions);
            })
            .catch((error) => {
                console.error('Error getting restaurants:' + JSON.stringify(error));
            });
        }
    }, [loggedIn, isAdmin]);

    useEffect(() => {
        if (restaurants.length > 0 && userInfo?.employer?.id) {
            const currentRestaurantOption = restaurants.find(r => r.value === userInfo.employer.id);
            if (currentRestaurantOption) {
                setSelectedRestaurant(currentRestaurantOption);
            }
        }
    }, [restaurants, userInfo?.employer?.id]);

    const handleRestaurantChange = (selectedOption) => {
        if (selectedOption.value !== selectedRestaurant?.value) {
            setRestaurant(selectedOption.value);
            setSelectedRestaurant(selectedOption);
        }
    };

    const openCreateRestaurantModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleAddRestaurant = () => {
        const restaurantName = newRestaurantName;
        if (restaurantName) {
            fetch('/api/settings/newrestaurant', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: restaurantName })
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.restaurant) {
                    const newRestaurantOption = { value: data.restaurant.id, label: data.restaurant.name };
                    setRestaurants([...restaurants, newRestaurantOption]);
                    setSelectedRestaurant(newRestaurantOption);
                    closeModal();
                }
            })
            .catch((error) => {
                alert('Error adding restaurant:' + JSON.stringify(error));
            });

            alert('Restaurant added');
        }
        // Logic to add restaurant
        closeModal();
    };

    const handleNewRestaurantNameChange = (event) => {
        setNewRestaurantName(event.target.value);
        // Logic to set new restaurant name
    }

    return (
        <>
        <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top d-none d-md-block">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">matsedeln.se</Link>
                <div className="navbar-collapse collapse justify-content-between">
                    <div className="navbar-nav">
                        {/* Your main navigation links go here */}
                        {loggedIn && (
                            <>
                                <Link to="/" className={`nav-item nav-link nav-link-btn ${isActive('/') ? 'active' : ''}`}>Luncher</Link>
                                <Link to="/addlunches" className={`nav-item nav-link nav-link-btn ${isActive('/addlunches') ? 'active' : ''}`}>Lägg till luncher</Link>
                                {/* <Link to="/dashboard" className={`nav-item nav-link nav-link-btn ${isActive('/dashboard') ? 'active' : ''}`}>Dashboard</Link> */}
                                <Link to="/settings" className={`nav-item nav-link nav-link-btn ${isActive('/settings') ? 'active' : ''}`}>Inställningar</Link>
                                {isAdmin && (
                                    <button 
                                        className={`btn btn-secondary btn-sm`}
                                        onClick={() => openCreateRestaurantModal()}
                                    >
                                        Skapa ny restaurang
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <div className="navbar-nav">
                        {/* Authentication links go here */}
                        {!loggedIn ? (
                            <>
                                <Link to="/login" className={`nav-item nav-link nav-link-btn ${isActive('/login') ? 'active' : ''}`}>Logga In</Link>
                                <Link to="/register" className={`nav-item nav-link nav-link-btn ${isActive('/register') ? 'active' : ''}`}>Registrera Dig</Link>
                            </>
                        ) : (
                            <>
                                <span className="nav-item nav-link d-flex align-items-center">
                                <div className='text-nowrap'>Inloggad som <b>{userInfo?.name}</b> på</div>
                                {isAdmin ? (
                                    <Select
                                        className='d-inline-block w-50 ms-2'
                                        value={selectedRestaurant}
                                        onChange={handleRestaurantChange}
                                        options={restaurants}
                                        placeholder="Välj restaurang"
                                    />
                                ) : (
                                    <b className='ms-2'>{userInfo?.employer?.name}</b>
                                )}
                                </span>
                                <button onClick={handleLogout} className="nav-item nav-link nav-link-btn btn btn-link">
                                    Logga Ut <FontAwesomeIcon icon={faSignOutAlt} />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </nav>
        {showModal && (
            <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Skapa ny restaurang</h5>
                            <button type="button" className="btn-close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="restaurantName" className="form-label">Namn på restaurang</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="restaurantName" 
                                        onChange={handleNewRestaurantNameChange} 
                                    />
                                </div>
                                {/* Additional fields as needed */}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeModal}>Avbryt</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddRestaurant}>Skapa</button>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
};

export default TopMenu;
