import React, { useEffect, useContext } from 'react';
import MenuItem from './MenuItem';
import { UserContext } from '../../context/UserContext';
import Graph from './Graph';

const Dashboard = () => {
  const { loggedIn, loading } = useContext(UserContext);

  useEffect(() => {
    const token = localStorage.getItem('token'); // or wherever you store your token
    if (!token || (!loading && !loggedIn)) {
      // Redirect to login if no token or not authenticated
      window.location.href = '/login';
      return;
    }
  }, [loading, loggedIn]);
  
  return (
    loggedIn && (
    <div className="container mt-5 p-3">
      <div className="row mt-4">
        <div className="col-6">
          <MenuItem title="Dagens lunch" content="-" />
        </div>
        <div className="col-6">
          <Graph title="Antal klick idag" />
        </div>
        <div className="col-6 mt-4">
          <MenuItem title="Veckans lunch" content="-" />
        </div>
        <div className="col-6 mt-4">
          <Graph title="Antal klick den här veckan" />
        </div>
      </div>
    </div>)
  );
}

export default Dashboard;

