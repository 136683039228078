// UserDetailsForm.js
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";

const UserDetailsForm = ({ userInfo }) => {
  const { getUserInfo } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState({
    name: "",
    address: "",
    email: "",
    company_mail: "",
    company_website: "",
    phoneNumber: "",
    companyNumber: "",
    houseNumber: "",
    county: "",
    position: {
      longitude: 0,
      latitude: 0,
    },
    standingMessage: "",
    defaultPrice: "",
  });

  useEffect(() => {
    if (userInfo && userInfo.name) setUserDetails({ ...userInfo });
  }, [userInfo]);

  const handleInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      fetch("/api/settings/restaurant", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      })
        .then(() => {
          getUserInfo();
          alert("Ändringarna har sparats!");
        })
        .catch((error) => {
          alert("Kunde inte spara ändringarna. Försök igen!");
          console.debug("Error saving changes:", error);
        });
    } catch (error) {
      alert("Kunde inte spara ändringarna. Försök igen!");
      console.debug("Error saving changes:", error);
    }
    // Add logic to submit user details
  };

  return (
    <div className="row rounded-2 mb-4 bg-gray-500 p-3">
      <div className="col">
        <h4 className="mb-3">Ändra företagsinformation</h4>
        <h5 className="mb-3">
          <b>(Privat)</b>
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label>Företagsmail</label>
            <input
              type="email"
              className="form-control"
              name="company_mail"
              value={userDetails.company_mail}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label>Organisationsnummer</label>
            <input
              type="text"
              className="form-control"
              name="companyNumber"
              value={userDetails.companyNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Spara Ändringar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserDetailsForm;
