import React from 'react';

const MenuItem = ({ title, content }) => (
  <div className="card">
    <div className="card-body">
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  </div>
);

export default MenuItem;

