import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            alert('Välj en bild först!');
            return;
        }

        const formData = new FormData();
        formData.append('profilePic', selectedFile);

        try {
            await axios.post('/api/settings/uploadpicture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            }).catch(error => {
                alert('Kunde inte spara ändringarna. Försök igen!');
                console.debug('Error saving changes:', error);
            }).then(res => {
                console.debug('Profilepic saved: ', res)
            })
            
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click(); // Explicitly trigger the file input
    };

    return (
        <div className="custom-file">
            <button className='btn btn-primary' onClick={triggerFileInput}>
                {selectedFile ? selectedFile.name : "Välj fil..."}
            </button>

            {selectedFile && (
                <>
                    <button type="button" className="btn btn-success ms-2" onClick={handleSaveChanges}>
                        <FontAwesomeIcon icon={faUpload} className='me-1' />
                        Ladda upp ny logga
                    </button>
                </>
            )}
            <input 
                type="file" 
                className="custom-file-input" 
                id="customFile" 
                onChange={handleFileChange} 
                hidden 
                ref={fileInputRef}
            />
        </div>
    );
};


export default FileUpload;
