// LogoSection.js
import React from 'react';
import FileUpload from '../../components/FileUpload'; // Update import path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const LogoSection = ({ userInfo }) => {
  return (
    <div className="row mb-4 p-3 bg-gray-500 rounded-2">
      <div className="col-auto">
        <div>
          {userInfo && userInfo.logo ? 
            <img src={`/images/${userInfo.logo}`} alt="Current Logo" className="img-fluid rounded-2" style={{ maxWidth: '200px', maxHeight: '200px' }} /> : 
            <FontAwesomeIcon icon={faImage} size="3x" />
          }
        </div>
      </div>

      <div className="col-6">
        <h4 className='mb-3'>Ändra Logga</h4>
        <FileUpload />  
      </div>
    </div>
  );
};

export default LogoSection;
