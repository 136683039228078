// src/components/Auth/Login.js
import React from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Policy() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <div className="container py-5">
          <button className="btn btn-secondary mb-3" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} />Tillbaka</button>
          <h2 className="mb-4">Integritetspolicy</h2>
      <div className="row">
        <div className="col">
            <h3>2024-02-06</h3>

            <p>Din integritet är viktig för oss. Denna integritetspolicy förklarar vilken typ av personlig information som samlas in när du använder vår webbplats, hur den används och skyddas.</p>

            <h4>Insamlad information</h4>
            <p>Vi samlar in information som du frivilligt tillhandahåller när du använder vår webbplats. Det kan inkludera namn, e-postadress och andra personuppgifter som du väljer att dela med oss.</p>

            <p>Vi använder också cookies och liknande spårningsteknik för att samla in information om din användning av webbplatsen för att förbättra din upplevelse och för att analysera webbplatstrafiken. Genom att använda vår webbplats samtycker du till användningen av cookies i enlighet med denna policy.</p>

            <h4>Användning av information</h4>
            <p>Den information vi samlar in används för att förbättra kvaliteten på våra tjänster, för att kommunicera med dig och för att analysera användarbeteenden för att anpassa och förbättra din upplevelse av vår webbplats.</p>

            <h4>Delning av information</h4>
            <p>Vi delar inte din personliga information med tredje part utan ditt samtycke, förutom när det är nödvändigt för att tillhandahålla de tjänster du begär eller om det krävs enligt lag.</p>

            <h4>Cookie-policy</h4>
            <p>Vår webbplats använder cookies för att förbättra användarupplevelsen. Genom att använda vår webbplats samtycker du till användningen av cookies i enlighet med vår cookie-policy.</p>

            <h4>Google Analytics</h4>
            <p>Vi använder Google Analytics för att analysera användarbeteenden på vår webbplats och för att förbättra dess prestanda och användarupplevelse. Genom att använda vår webbplats samtycker du till Googles behandling av data enligt deras integritetspolicy.</p>

            <h4>Dina rättigheter</h4>
            <p>Du har rätt att begära åtkomst till och rättelse av din personliga information som vi har. Om du vill begära en kopia av den information som vi har om dig, vänligen kontakta oss.</p>

            <h4>Ändringar i integritetspolicyn</h4>
            <p>Vi förbehåller oss rätten att uppdatera och ändra denna integritetspolicy när som helst. Vi rekommenderar att du regelbundet granskar denna sida för att hålla dig informerad om eventuella ändringar.</p>

            <h4>Kontakta oss</h4>
            <p>Om du har frågor eller funderingar angående vår integritetspolicy eller vår hantering av din personliga information, vänligen kontakta oss.</p>
            
        </div>
      </div>
    </div>
  );
}

export default Policy;
