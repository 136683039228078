import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { UserContext } from '../../context/UserContext';
import './styles.css';
import '../../styles.css';
import { searchOptions } from '../../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PDFViewerWeeklyLunch from '../../components/PDFViewerWeeklyLunch'

function WeeklyLunchSchemaUpload() {
    const { loggedIn, loading, userInfo } = useContext(UserContext);
    const [week, setWeek] = useState(moment().week());
    // const [dateArray, setDateArray] = useState([]);
    const [restaurantId, setRestaurantId] = useState(false);
    const [dailyOffers, setDailyOffers] = useState(Array.from({ length: 7 }, () => []));
    const [defaultPrice, setDefaultPrice] = useState(false);
    const [pageReady, setPageReady] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token || (!loading && !loggedIn)) {
            window.location.href = '/login';
            return;
        }
    }, [loading, loggedIn]);

    useEffect(() => {
        if (userInfo && userInfo.employer) {
            setRestaurantId(userInfo.employer.id);
            const defaultPrice = userInfo.employer.default_price;
            setDefaultPrice(parseInt(defaultPrice) || false);
        }
    }, [userInfo]);

    useEffect(() => {
        if (week && restaurantId) {
            const fetchOffers = async () => {
                try {
                    setPageReady(false);

                    const response = await axios.get(`/api/lunchmenu/weekly/${week}?restaurant=${restaurantId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });

                    setPageReady(true);

                    if (response.data && 
                        response.data.length && 
                        response.data.length > 0 && 
                        response.data[0].lunches && 
                        response.data[0].lunches.length > 0) {
                        setDailyOffers(response.data[0].lunches);
                    } else {
                        setDailyOffers(Array.from({ length: 7 }, () => []));
                    }
                } catch (error) {
                    console.error('Error fetching schema.', error);
                }
            };

            fetchOffers();
        }
    }, [week, restaurantId]);

    function getDaysForWeek(weekNumber) {
        // Might have to make year dynamic to handle year shifts.
        const year = moment().year();

        const dayNamesSwedish = {
            'Monday': 'Måndag',
            'Tuesday': 'Tisdag',
            'Wednesday': 'Onsdag',
            'Thursday': 'Torsdag',
            'Friday': 'Fredag',
            'Saturday': 'Lördag',
            'Sunday': 'Söndag'
        };

        // Set the week number and year for the moment object
        const firstDayOfWeek = moment().year(year).isoWeek(weekNumber).startOf('isoWeek');
        const days = [];
    
        // Loop through each day of the week, starting from Monday (index 0)
        for (let i = 0; i < 7; i++) {
            // Add the day name (in Swedish) and the date to the array
            const dayName = dayNamesSwedish[firstDayOfWeek.clone().add(i, 'days').format('dddd')]
            const date = firstDayOfWeek.clone().add(i, 'days').format('DD MMM')
            days.push(`${dayName} - ${date}`);
        }
    
        return days;
    }
    

    const handleWeekInputChange = (weekNumber) => {
        if (parseInt(weekNumber) !== undefined && weekNumber > 0) {
            if (weekNumber > 52) {
                weekNumber = 52;
            }
            setWeek(weekNumber);
        } else {
            setWeek(false);
        }
    };

    const handleAddLunch = (dayIndex, description = '', price = defaultPrice) => {
        const newOffers = [...dailyOffers];
        newOffers[dayIndex] = [...newOffers[dayIndex], { description: description, price: defaultPrice }];
        setDailyOffers(newOffers);
    };

    const handleOfferChange = (dayIndex, offerIndex, field, value) => {
        const newOffers = [...dailyOffers];
        newOffers[dayIndex][offerIndex][field] = value;
        setDailyOffers(newOffers);
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                lunches: dailyOffers
            };

            try {
                fetch(`/api/lunchmenu/weekly/${week}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                }).then((response) => {
                    if (response.status === 201) {
                        alert('Lunchmeny sparad!');
                    } else {
                        alert(`Kunde inte spara lunchmenyn!`)
                        console.error('Kunde inte spara lunchmenyn:', response);
                    }
                }).catch(error => {
                    alert(`Kunde inte ladda upp lunchmeny: ${error}`)
                    console.debug('Error changing password:', error);
                });
            } catch (error) {
                console.error('Error fetching schema.', error);
            }
        } catch (error) {
            console.error('Error uploading schema.', error);
        }
    };

    return (
        loggedIn && (

            <div className="container mt-5 p-3 page-container bg-gray-200">
                <div className="container mt-5 p-3 m-2 page-container min-vh-100">
                    <h2 className="text-center mb-5">Lägg till luncher</h2>

                    {/* Week Selector */}
                    <div className="d-flex justify-content-center mb-4">
                        <button className="btn btn-sm btn-primary mx-2 text-nowrap" onClick={() => setWeek(moment().week())}>Nuvarande Vecka</button>
                        <button className="btn btn-sm btn-secondary mx-2 text-nowrap" onClick={() => handleWeekInputChange(week - 1)}><FontAwesomeIcon icon={faChevronLeft}/></button>
                        <div className='col-auto'>
                            <input type="number" minLength={1} maxLength={2} placeholder="Week #" className="form-control" value={week || ''} onChange={e => handleWeekInputChange(parseInt(e.target.value))} />
                        </div>
                        <button className="btn btn-sm btn-secondary mx-2 text-nowrap" onClick={() => handleWeekInputChange(week + 1)}><FontAwesomeIcon icon={faChevronRight}/></button>
                        <PDFViewerWeeklyLunch week={week} restaurantId={restaurantId}/>
                    </div>
                    {!pageReady ? (
                    <div className="text-center mt-5">
                        <h2>Laddar...</h2>
                    </div>
                ) : week && (
                        <div className='py-3 mb-5'>
                            {dailyOffers.map((offers, dayIndex) => (
                                <div key={dayIndex} className="mb-4 p-2 bg-gray-500 rounded-2">
                                    <h5 className="mb-2 text-gray-800">{getDaysForWeek(week)[dayIndex]}</h5>
                                    {offers.map((offer, offerIndex) => (
                                        <div key={offerIndex} className="row mb-2 align-items-center">
                                            <div className="col-6 col-md-6">
                                                <input type="text" className="form-control" placeholder="Beskrivning" value={offer.description} onChange={e => handleOfferChange(dayIndex, offerIndex, 'description', e.target.value)} />
                                            </div>
                                            <div className="col-2 col-md-2">
                                                <input type="number" className="form-control" placeholder="Pris" value={offer.price ?? defaultPrice ?? ''} onChange={e => handleOfferChange(dayIndex, offerIndex, 'price', e.target.value)} />
                                            </div>
                                            <div className="col-3 col-md-3">
                                                <Select
                                                    isMulti
                                                    name="Taggar"
                                                    options={searchOptions}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Taggar..."
                                                    value={offer.tags}
                                                    onChange={e => handleOfferChange(dayIndex, offerIndex, 'tags', e)}
                                                />
                                            </div>
                                            <div className="col-1 col-md-1">
                                                <button 
                                                    className='btn btn-danger btn-sm'
                                                    onClick={() => {
                                                        const newOffers = [...dailyOffers];
                                                        newOffers[dayIndex].splice(offerIndex, 1);
                                                        setDailyOffers(newOffers);
                                                    }}
                                                >X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => handleAddLunch(dayIndex)}>Lägg till alternativ</button>
                                    </div>
                                </div>
                            ))}
                            <div className="text-center">
                                <button type="button" className="btn btn-lg btn-primary" onClick={() => handleSubmit()}>Spara vecka</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    )
}

export default WeeklyLunchSchemaUpload;
