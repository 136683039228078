// RestaurantShowMore.js

import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function RestaurantShowMore({ show, restaurantInfo, closeModal }) {
  if (!show) {
    return null;
  }
  const {
    name,
    address,
    house_number,
    county,
    tele,
    email,
    company_website,
    welcome_message,
    logo,
  } = restaurantInfo;
  let modalTitle = name.toLowerCase().includes("restaurang")
    ? name
    : name + " - Restaurang";
  let addressString = `${address} ${house_number}, ${county}`;
  let googleMapsLink = `https://maps.google.com/?q=${addressString}`;

  return (
    <div className="modal show" style={{ display: "block" }} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modalTitle}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="restaurant-info">
              <div className="logo-container">
                <img src={`images/${logo}`} alt="" />
              </div>
              <div className="info-details">
                {address && (
                  <p className="address">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <a className="link-unstyled ms-1" href={googleMapsLink}>
                      {addressString}
                    </a>
                  </p>
                )}
                {tele && (
                  <p className="contact-info">
                    <FontAwesomeIcon icon={faPhone} />
                    <a
                      className="link-unstyled ms-1"
                      href={"tel:" + String(tele)}
                    >
                      {tele}
                    </a>
                  </p>
                )}
                {email && (
                  <p className="contact-info">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a className="link-unstyled ms-1" href={"mailto:" + email}>
                      {email}
                    </a>
                  </p>
                )}
                {company_website && (
                  <p className="contact-info">
                    <FontAwesomeIcon icon={faGlobe} />
                    <a
                      className="link-unstyled ms-1"
                      href={`https://${company_website}`}
                    >
                      {company_website}
                    </a>
                  </p>
                )}
              </div>
            </div>
            <div className="welcome-message">
              <p>
	  	<small className="text-muted">
	  	  {welcome_message}
	 	</small>
	      </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantShowMore;
