import React, { useEffect, useContext, useState } from "react";
import LogoSection from "./LogoSection";
import UserDetailsForm from "./UserDetailsForm";
import CompanyForm from "./CompanyForm";
import PasswordChangeForm from "./PasswordChangeForm";
import RestaurantCodes from "./RestaurantCodes";
import DeleteSection from "./DeleteSection";
import { UserContext } from "../../context/UserContext";

const Settings = () => {
  const { loggedIn, loading, userInfo, isAdmin } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState({
    username: "",
    name: "",
    address: "",
    houseNumber: "",
    county: "",
    email: "",
    company_mail: "",
    company_website: "",
    phoneNumber: "",
    standingMessage: "",
    welcome_message: "",
    defaultPrice: "",
    logo: "",
  });

  useEffect(() => {
    if (userInfo && userInfo.employer) {
      setUserDetails({
        username: userInfo.name,
        name: userInfo.employer.name,
        address: userInfo.employer.address,
        houseNumber: userInfo.employer.house_number,
        county: userInfo.employer.county,
        email: userInfo.employer.email,
        company_mail: userInfo.employer.company_mail,
        company_website: userInfo.employer.company_website,
        phoneNumber: userInfo.employer.tele,
        standingMessage: userInfo.employer.standing_message,
        welcome_message: userInfo.employer.welcome_message,
        companyNumber: userInfo.employer.company_number,
        position: userInfo.employer.position,
        defaultPrice: userInfo.employer.default_price,
        logo: userInfo.employer.logo,
      });
    }

    const token = localStorage.getItem("token");
    if (!token || (!loading && !loggedIn)) {
      window.location.href = "/login";
      return;
    }
  }, [loading, loggedIn, userInfo]);

  return (
    loggedIn && (
      <div className="container bg-gray-200">
        <div className="container-fluid mt-5 p-5">
          <h2 className="mb-4 text-center">Inställningar</h2>
          <UserDetailsForm userInfo={userDetails} />
          <CompanyForm userInfo={userDetails} />
          <LogoSection userInfo={userDetails} />
          <PasswordChangeForm userInfo={userDetails} />
          {isAdmin && <RestaurantCodes userInfo={userDetails} />}
          {isAdmin && <DeleteSection />}
        </div>
      </div>
    )
  );
};

export default Settings;
