import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const GeoSearch = ({ onChange, className, value, limit = 30 }) => {
    // Initialize the state with the value prop, or null if it's not provided
    const [selectedValue, setSelectedValue] = useState(value ? { label: value, value: value } : null);

    const loadOptions = (inputValue) =>
    new Promise(async (resolve, reject) => {
        try {
            const searchTerm = inputValue.toLowerCase();
            const response = await axios.get(`/search?q=${searchTerm}&limit=${limit}`).catch(reject);
            const data = response.data ?? [];
            const options = data.map(obj => {
                return {
                    ...obj,
                    label: `${obj.name} - ${obj.county}`,
                    value: obj.name,
                }
            })
            resolve(options);
        } catch (err) {
            reject(err);
        }
    });

    useEffect(() => {
        if(value && typeof value === 'object' && value.value) {
            setSelectedValue({ ...value });
        } else if (typeof value === 'string'){
            setSelectedValue({value: value, label: value});
        } else {
            setSelectedValue(null);
        }
    }, [value]);

    return (
        <AsyncSelect
            cacheOptions
            className={className}
            loadOptions={loadOptions}
            placeholder="Välj plats att utgå ifrån..."
            value={selectedValue}
            noOptionsMessage={() => null}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            onChange={(selectedOption) => {
                setSelectedValue(selectedOption);
                onChange(selectedOption);
            }}
        />
    );
};

export default GeoSearch;